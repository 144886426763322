import './App.scss';
import { Achievements } from './components/Achievements/Achievements';
import Contact from './components/Contact';
import CP from './components/CP/CP';
import Experience from './components/Experience/Experience';
import Github from './components/Github';
import Landing from './components/Landing';
import Navbar from './components/Navbar';
import Projects from './components/Projects/Projects';
import Skills from './components/Skills/Skills';
import Talks from './components/Talks';
// import PropagateLoader from "react-spinners/PropagateLoader";

import AnimatedCursor from "react-animated-cursor"
import { useEffect, useState } from 'react';
// import { css } from "@emotion/react";
import loadingVid from './img/loading.mp4'

const App = () => {

  const [loading, setLoading] = useState(false)

  // const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
  const [theme, setTheme] = useState(localStorage.getItem("theme"))
  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false);
    }, 4500)
  }, [])
  useEffect(() => {
    if (!theme) {
      const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
      localStorage.setItem("theme", defaultDark ? "dark" : "light")
      setTheme(defaultDark ? "dark" : "light")
    }
  }, [theme])

  const toggleTheme = () => {
    let newTheme = 'light';
    if (localStorage.getItem("theme") === "light") {
      localStorage.setItem("theme", "dark")
      newTheme = 'dark'
    } else {
      localStorage.setItem("theme", "light")
    }
    setTheme(newTheme)
  }
  //   const override = css`
  //   display: block;
  //   margin: 0 auto;
  //   position: absolute;
  //   top: 45%;
  //   left: 48%
  // `;
  return (
    <>
      {loading ?
        <div className='loader'>
          {/* <PropagateLoader color="#6736DD" loading={loading} css={override} size={20} /> */}
          <div className='loader-box'>
            <video autoPlay muted playsInline >
              <source src={loadingVid} type="video/mp4" />
            </video>
          </div>
        </div>
        :
        <div className={'App App-' + theme}>
          <AnimatedCursor
            innerSize={10}
            outerSize={8}
            color='16, 196, 248'
            outerAlpha={0.2}
            innerScale={0.4}
            outerScale={2}
            clickables={[
              'a',
              'input[type="text"]',
              'input[type="email"]',
              'input[type="number"]',
              'input[type="submit"]',
              'input[type="image"]',
              'label[for]',
              'select',
              'textarea',
              'button',
              '.link'
            ]}
          />
          <Navbar theme={theme} />
          <div className="theme-toggle-button">
            <input type="checkbox" className="checkbox" id="checkbox" onClick={toggleTheme} defaultChecked={theme === "dark" ? true : false} />
            <label htmlFor="checkbox" className="label">
              <i className='fas fa-sun'></i>
              <i className="fas fa-moon"></i>
              <div className='ball'></div>
            </label>
          </div>
          {/* <button className='theme-toggle-button' onClick={toggleTheme}>Change</button> */}
          <Landing theme={theme} />
          <Experience theme={theme} />
          <Skills theme={theme} />
          <Projects theme={theme} />
          <Achievements theme={theme} />
          <Github theme={theme} />
          <CP theme={theme} />
          <Talks theme={theme} />
          <Contact theme={theme} />
        </div>
      }

    </>
  );
}

export default App;
