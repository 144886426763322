const Icons = {
    "cpp":
    {
        source: "https://img.icons8.com/color/96/000000/c-plus-plus-logo.png"
    },
    "js":
    {
        source: "https://img.icons8.com/color/96/000000/javascript--v1.png"
    },
    "python":
    {
        source: "https://img.icons8.com/color/96/000000/python--v1.png"
    },
    "c":
    {
        source: "https://img.icons8.com/color/48/000000/c-programming.png"
    },
    "react":
    {
        source: "https://img.icons8.com/office/80/000000/react.png"
    },
    "html":
    {
        source: "https://img.icons8.com/color/48/000000/html-5--v1.png"
    },
    "css": "https://img.icons8.com/color/48/000000/css3.png",
    "bootstrap": "https://img.icons8.com/color/48/000000/bootstrap.png",
    "node": "https://img.icons8.com/color/48/000000/nodejs.png",
    "express": "https://www.vectorlogo.zone/logos/expressjs/expressjs-ar21.svg",
    "mongo": "https://img.icons8.com/color/48/000000/mongodb.png",
    "sql": "https://img.icons8.com/office/40/000000/database.png",
    "git": "https://img.icons8.com/color/48/000000/git.png",
    "linux": "https://img.icons8.com/color/48/000000/linux.png",
    "sass": require('../../img/SkillIcons/sass.png'),
    "github": require('../../img/SkillIcons/github.png'),
    "firebase": require('../../img/SkillIcons/firebase.png'),
    "canva": require('../../img/SkillIcons/canva.png'),
    "figma": require('../../img/SkillIcons/figma.png'),
}

export default Icons
