import React from 'react'
import '../../styles/ProjectCard.scss'
import { useState } from 'react'

const Card = ({ project, theme }) => {

    const [imgclass, setImgclass] = useState("img-fluid project-card-img-top project-card-img-top-" + theme);

    return (
        <div className="col projects-col">
            {/* <div className='projects-up-icon-box'>
                <i className="projects-up-icon fas fa-caret-up"></i>
            </div> */}
            <div className={"card mb-3 project-card project-card-" + theme}>
                {project.ribbon && !imgclass.includes('project-card-img-hovered') &&
                    <div className="ribbon ribbon-top-left">
                        <span className={'ribbon-' + project.ribbon}>{project.ribbon}</span>
                    </div>
                }
                {/* <div className='box-shadow-inner'></div> */}
                <img className={imgclass} src={project.img_link} alt="" />

                <div className={"card-body project-card-body project-card-body-" + theme}
                    onMouseEnter={() => setImgclass("img-fluid project-card-img-top project-card-img-hovered project-card-img-top-" + theme)}
                    onMouseLeave={() => setImgclass("img-fluid project-card-img-top project-card-img-top-" + theme)}
                >
                    <div className="row">
                        <div className="col-7">
                            <div className="card-course-main">
                                <h5 className="card-title">
                                    {project.name}
                                </h5>
                                <p className={imgclass.includes('project-card-img-hovered') ? "d-block" : "d-none"}>
                                    {project.desc}
                                </p>
                                <p className={imgclass.includes('project-card-img-hovered') ? "d-none" : "d-block"}>
                                    {project.desc.length > 96 ? project.desc.slice(0, 96) + '...' : project.desc}
                                </p>
                            </div>
                        </div>
                        <div className="col-5 tags-col">
                            {project.techs.map((tech, idx) => (
                                <div className={`tags tags-${theme} card-tags-${theme}`} key={idx} >
                                    <a href="/" >
                                        <div >
                                            {tech}
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                        <div className='project-links'>
                            {project.web_link &&
                                <a className="projects-view-btn" rel="noreferrer" href={project.web_link} target="_blank" title="View Website">
                                    <i className={"fa fa-link projects-icon projects-icon-" + theme}></i>
                                </a>

                            }
                            {project.github_link &&
                                <a className="projects-view-btn" rel="noreferrer" href={project.github_link} target="_blank" title="View Source">
                                    <i className={"fab fa-github  projects-icon projects-icon-" + theme}></i>
                                </a>
                            }
                            {project.demo_link &&
                                <a className="projects-view-btn" rel="noreferrer" href={project.demo_link} target="_blank" title="View Demo">
                                    <i className={"fa fa-youtube  projects-icon projects-icon-" + theme}></i>
                                </a>
                            }
                        </div>
                    </div>


                </div>
            </div >
        </div >
    )
}

export default Card
