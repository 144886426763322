import React from 'react'
import Heading from './Heading'
import '../styles/Contact.scss'
import contactimg from '../img/static/temp_contact_transparent.png'
import { useForm } from '@formspree/react';
import { Zoom } from 'react-awesome-reveal';
import logo from '../img/logo_transparent.png'

const Contact = ({ theme }) => {
    function ContactForm() {
        const [state, handleSubmit] = useForm("mqknwdvb");
        if (state.succeeded) {
            return (
                <div className={'contact-form-acknowledgement contact-form-acknowledgement-' + theme}>
                    <h3>Thank you!</h3>
                    <p>Your message has been sent and you will get a response to the entered email.</p>
                </div>);
        }
        return (
            <form onSubmit={handleSubmit}>
                <div>
                    <p>Your Name</p>
                    <input type="text" name="name" id="name"
                        required />
                </div>
                <div>
                    <p>Your Email</p>
                    <input id="email"
                        type="email"
                        name="email"
                        required
                    />
                </div>
                <div>
                    <p>Your Message</p>
                    <textarea
                        id="message"
                        name="message"
                        required
                    ></textarea>
                </div>
                <button type="submit" disabled={state.submitting} className={'contact-submit-btn contact-submit-btn-' + theme}>
                    Submit
                </button>
            </form>
        );
    }
    // const handleSubmit = (e) => {
    //     e.preventDefault();

    //     const formData = new FormData()
    //     formData.append(GOOGLE_FORM_NAME_ID, name)
    //     formData.append(GOOGLE_FORM_MESSAGE_ID, message)
    //     formData.append(GOOGLE_FORM_EMAIL_ID, email)

    //     axios.post(CORS_PROXY + GOOGLE_FORM_ACTION_URL, formData)
    //         .then(() => {
    //             setSubmitted(true)
    //         })
    // }

    return (
        <div id="contact">
            <div>
            </div>
            <section className={"vshape vshape-" + theme}>
            </section>
            <div>
                <div className={'contact-main contact-main-' + theme}>
                    <Heading title="Contact" theme={theme} special={true} />
                    <Zoom>
                        <div className={'contact-card contact-card-' + theme}>
                            <div className='row'>
                                <div className={'col-md-6 col-12 contact-inputs contact-inputs-' + theme}>
                                    <div>
                                        <h3>Have a question for me?</h3>
                                        <h5>Let's talk!</h5>
                                    </div>
                                    <ContactForm />
                                </div>
                                <div className='col-md-6 col-12 contact-img-box'>
                                    <img className='contact-img' alt="vector" src={contactimg} />
                                </div>
                            </div>
                        </div>
                    </Zoom>
                </div >
                <footer className={'contact-footer contact-footer-' + theme}>
                    {/* <button id="topbtn" title="Go to top">
                        <i className="m-auto fas fa-arrow-circle-up"></i></button> */}
                    <div className="flex icon-wrap">
                        <a href="https://www.linkedin.com/in/vishruth-subramanian-40737a1a1/" target="_blank" rel='noreferrer'>
                            <div className="icon-box">
                                <i className="fab fa-linkedin-in"></i>
                            </div>
                        </a>
                        <a href="https://github.com/Vishruth-S" target="_blank" rel='noreferrer'>
                            <div className="icon-box">
                                <i className="fab fa-github"></i>
                            </div>
                        </a>
                        <a href="https://twitter.com/Vishruth_S_" target="_blank" rel='noreferrer'>
                            <div className="icon-box">
                                <i className="fab fa-twitter"></i>
                            </div>
                        </a>
                        <a href="https://instagram.com/vishruth_s_" target="_blank" rel='noreferrer'>
                            <div className="icon-box">
                                <i className="fab fa-instagram"></i>
                            </div>
                        </a>
                        <a href="https://www.facebook.com/profile.php?id=100006962836486" target="_blank" rel='noreferrer'>
                            <div className="icon-box">
                                <i className="fab fa-facebook"></i>
                            </div>
                        </a>
                    </div>
                    <div className="footertext">
                        <div><img src={logo} alt="logo" /></div>
                        <p>Designed and Developed by Vishruth Subramanian</p>
                        <p><span>&#169; 2023</span> Vishruth Subramanian</p>
                    </div>
                </footer>
            </div>
        </div>
    )
}

export default Contact