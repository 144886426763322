import React from 'react'

const Heading = ({ title, theme, special }) => {
    return (
        <div className='text-center'>
            <h1 className={`global-heading color-${special ? "dark" : theme}`} >
                <span className={'underline-' + theme}>
                    {title}
                </span>
            </h1>
        </div >
    )
}

export default Heading