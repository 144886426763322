const ProjectList = [
    {
        _id: 5,
        name: "Codie",
        img_link: require("../img/ProjectImgs/codie.png"),
        desc: "Chrome Extension of an AI Chatbot aimed at Interview Preparation which answers any doubts on the question, helps you optimize your code and even takes interviews for you",
        tags: ["full-stack"],
        techs: ["AI", "GPT-3", "react", "html", "css", "javascript", "bootstrap"],
        demo_link: "https://youtu.be/Kma3u3WIhRQ",
        ribbon: "award",
    },
    {
        _id: 10,
        name: "SortSimple",
        img_link: require("../img/ProjectImgs/sortsimple.png"),
        desc: "Sorting visualizer to visualize various sorting algorithms step by step. Helps give intuitive understanding of the workings of each algorithm.",
        tags: ["front-end"],
        techs: ["react", "html", "css", "javascript", "bootstrap"],
        web_link: "https://vishruth-s.github.io/SortSimple/",
        github_link: "https://github.com/Vishruth-S/SortSimple",
        ribbon: "featured",
    },
    {
        _id: 20,
        name: "Official Website for CS Dept",
        img_link: require("../img/ProjectImgs/meccs.png"),
        desc: "Worked as Project Lead for the Website for the Department of Computer Engineering, Govt Model Engineering College, Thrikkakara. The website includes over 15 different pages, Faculty login & profile, Club login & profile, Admin authentication and more. ",
        tags: ["full-stack", "databases"],
        techs: ["nodejs", "express", "mongoDB", "reactjs", "css"],
        web_link: "http://cs.mec.ac.in",
        ribbon: "featured",
    },
    {
        _id: 30,
        name: "TeachEasy",
        img_link: require("../img/ProjectImgs/teacheasy.png"),
        desc: "Video conferencing application specifically aimed at online teaching. Includes features such as video and audio call, mute audio and video, collaborative Whiteboard and automatic attendance. ",
        tags: ["front-end"],
        techs: ["nodejs", "ejs", "socketio", "javascript", "html", "css"],
        github_link: "https://github.com/Vishruth-S/TeachEasy",
        demo_link: "https://youtu.be/YzbGFNSXcyE",
        ribbon: "award"
    },
    {
        _id: 40,
        name: "Infix2Postfix",
        img_link: require("../img/ProjectImgs/infix2postfix.png"),
        desc: "An application to convert any infix expression to its postfix form with all steps shown in a table.",
        tags: ["front-end"],
        techs: ["react", "html", "css", "javascript"],
        web_link: "https://vishruth-s.github.io/InfixToPostfix_Converter/",
        github_link: "https://github.com/Vishruth-S/InfixToPostfix_Converter",
        ribbon: "featured",
    },
    {
        _id: 50,
        name: "Bookspace",
        img_link: require("../img/ProjectImgs/book2.png"),
        desc: "Online platform for buying/selling books, includes features such as user account, authentication, buy/sell books, search for a book and view seller location.",
        tags: ["full-stack", "databases"],
        techs: ["nodejs", "express", "mongoDB", "html", "css", "javascript"],
        github_link: "https://github.com/Vishruth-S/BOOKspace"
    },
    {
        _id: 60,
        name: "MaxMoney",
        img_link: require("../img/ProjectImgs/maxmoney.png"),
        desc: "Design concept for a neo-banking website",
        tags: ["front-end", "react", "firebase", "full-stack", "databases"],
        techs: ["react", "firebase", "html", "css", "javascript"],
        github_link: "https://github.com/Vishruth-S/runtime-terror",
        demo_link: "https://drive.google.com/file/d/1mSWmU8DSc6TYPIcx9nS92jyCtpCfzCWT/view?usp=sharing",
        ribbon: "award"
    },
    {
        _id: 70,
        name: "Courseify",
        img_link: require("../img/ProjectImgs/courseify.png"),
        desc: "An online education courses website. Includes features such as creating account and login, search for course, filter courses by tags, enroll for any course, give reviews and more",
        tags: ["full-stack", "databases"],
        techs: ["nodejs", "express", "mongoDB", "html", "css", "javascript"],
        github_link: "https://github.com/Vishruth-S/courseify",
        demo_link: "https://youtu.be/es6c_vim_mY"
    },
    {
        _id: 80,
        name: "QwikChat",
        img_link: require("../img/ProjectImgs/qwikchat.png"),
        desc: "An instant one time chat application that allows to chat with any number of persons in a room, supports emojis, dark mode and more",
        tags: ["full-stack"],
        techs: ["react", "nodejs", "express", "html", "css", "javascript"],
        github_link: "https://github.com/Vishruth-S/QwikCHAT",
    },
    {
        _id: 90,
        name: "Weather App",
        img_link: require("../img/ProjectImgs/weather.png"),
        desc: "A simple website that displays the current weather info(temperature, humidity, wind-speed etc) of any location in the world",
        tags: ["front-end"],
        techs: ["react", "html", "css", "javascript"],
        web_link: "https://vishruth-s.github.io/React_Weather_App",
        github_link: "https://github.com/Vishruth-S/React_Weather_App",
    },
    {
        _id: 100,
        name: "Eatssy",
        img_link: require("../img/ProjectImgs/eatssy2.png"),
        desc: "Front-end for a food delivery service",
        tags: ["front-end"],
        techs: ["html", "css", "javascript"],
        web_link: "https://vishruth-s.github.io/Food_app/",
        github_link: "https://github.com/Vishruth-S/Food_app",
    },
    {
        _id: 110,
        name: "Prepo",
        img_link: require("../img/ProjectImgs/prepo.png"),
        desc: "A chrome extension that aims to ease the process of preparing for placements and internships by providing a one-stop place to easily organize resources, keep track of job applications, add tasks and more.",
        tags: ["front-end", "chrome-extension"],
        techs: ["react", "html", "css", "javascript"],
        github_link: "https://github.com/Vishruth-S/hft_project",
        demo_link: "https://youtu.be/GrVgvmRwRvU"
    },
    {
        _id: 120,
        name: "Library management System",
        img_link: require("../img/ProjectImgs/lms.png"),
        desc: "Library management System for easily finding books, issuing books, managing fines and more.",
        tags: ["full-stack", "databases"],
        techs: ["react", "firebase", "html", "css", "javascript"],
        github_link: "https://github.com/Vishruth-S/dbms_project",
    },
    {
        _id: 130,
        name: "RGB Color Game",
        img_link: require("../img/ProjectImgs/rgb.png"),
        desc: "A simple game where the user is given an RGB value and has to guess the color. Contains easy and hard modes. Made using JavaScript",
        tags: ["front-end"],
        techs: ["html", "css", "javascript"],
        web_link: "https://vishruth-s.github.io/project_colorgame/",
        github_link: "https://github.com/Vishruth-S/project_colorgame",
    },
    {
        _id: 140,
        name: "BookMyMovie",
        img_link: require("../img/ProjectImgs/bookmymovie.png"),
        desc: "High school project of a movie booking application, done completely in C++. ",
        tags: ["front-end"],
        techs: ["C++"],
        github_link: "https://github.com/Vishruth-S/Nostalgia",
    }
]

export default ProjectList