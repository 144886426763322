import React from 'react'
import Heading from './Heading'
import '../styles/Talks.scss'
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'
import { JackInTheBox } from 'react-awesome-reveal';

const Talks = ({ theme }) => {
    const temp = [{
        id: 1,
        title: "Intoduction to STL in C++",
        date: "Feb 27, 2021",
        video_id: "LwATYm8NKrY",
        link: '/'
    },
    {
        id: 2,
        title: "Algorithms and Time Complexity",
        date: "Jan 18, 2022",
        video_id: "KgBEaUoRixY",
        link: '/'
    }, {
        id: 3,
        title: "Problem Solving Sprint",
        date: "Feb 2, 2022",
        video_id: "d-S9m-6OTEk",
        link: '/'
    },
    ]
    return (
        <div id="talks" className={'talks-main talks-main-' + theme}>
            <Heading title="Talks" theme={theme} />
            <div className='talks-container'>
                <div className="row row-cols-1 row-cols-md-3 g-4">
                    {temp.map(t => (
                        <div key={t.id} className="col talks-card-col">
                            <JackInTheBox>
                                <div className={"card talks-card talks-card-" + theme}>
                                    <div className='talks-card-top'>
                                        <h2 className="card-title talks-card-title">{t.title}</h2>
                                        <p className="card-text"><small className="text-muted">{t.date}</small></p>
                                    </div>
                                    <div className={'talks-youtube-embed talks-youtube-embed-' + theme}>
                                        <LiteYouTubeEmbed
                                            id={t.video_id}
                                            title={t.title}
                                        />
                                    </div>
                                </div>
                            </JackInTheBox>
                        </div>
                    ))}
                </div>
                {/* <div className="card-body talks-card-body">
                    <button className='talks-btn'>View </button>
                </div> */}
            </div>
        </div>
    )
}

export default Talks