import React from 'react'
import '../../styles/Achievements.scss'
import certificate from '../../img/static/certificate3.png'
import certificate_dark from '../../img/static/certificate3_dark.png'
import useWindowDimensions from '../../utils/WindowDimensions'

const AchievementCard = ({ achievement, theme }) => {
    const { width } = useWindowDimensions();
    return (
        <div className='temp' key={achievement.id}>
            {width > 600 ?
                <div className={'achievement-card-container achievement-card-container-' + theme}>
                    <div className='row'>
                        <div className='col-2 achievements-icon-box'>
                            <img src={achievement.img_link} alt="first" className='achievements-medal' />
                            <div className='achievements-certificate-icon'>
                                <a href={achievement.certificate_link} target="_blank" rel="noreferrer">
                                    <img src={theme === "light" ? certificate : certificate_dark} alt="certificate" />
                                    <p>View Certificate</p>
                                </a>
                            </div>
                        </div>
                        <div className='col-10 achievements-right'>
                            <div className='row'>
                                <div className='col-9'>
                                    <h2>{achievement.prize}</h2>
                                </div>
                                <div className='col-3'>
                                    <p className='ac-date'>{achievement.date}</p>
                                </div>
                            </div>
                            <div className='row achivements-subtitle-row'>
                                <div className='col-6 achievements-subtitle-col1'>
                                    <h4>{achievement.title}</h4>
                                    <div className="achievements-vl"></div>
                                </div>
                                <div className='col-6'>
                                    <h4>{achievement.org}</h4>
                                </div>
                            </div>
                            <p>
                                {achievement.desc}
                            </p>
                        </div>
                    </div>
                </div>
                :
                <div className={'achievement-card-container achievement-card-container-' + theme}>
                    <div className='row'>
                        <div className='col-2 achievements-icon-box'>
                            <img src={achievement.img_link} alt="first" className='achievements-medal' />
                        </div>
                        <div className='col-10 achievements-right'>
                            <div className='row'>
                                <div className='col-9'>
                                    <h2>{achievement.prize}</h2>
                                </div>
                                <div className='col-3'>
                                    <p>{achievement.date}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row achivements-subtitle-row'>
                        <div className='col-6 achievements-subtitle-col1'>
                            <h4>{achievement.title}</h4>
                        </div>
                        <div className='col-6 achievements-subtitle-col2'>
                            <h4>{achievement.org}</h4>
                        </div>
                        <div className="achievements-vl"></div>
                    </div>
                    <div className='achievements-mobile-div'>
                        <p>
                            {achievement.desc}
                        </p>
                        <div className='achievements-certificate-icon'>
                            <a href={achievement.certificate_link} target="_blank" rel="noreferrer">
                                <img src={theme === "light" ? certificate : certificate_dark} alt="certificate" />
                                <p>View Certificate</p>
                            </a>
                        </div>
                    </div>
                </div>
            }
        </div >
    )
}

export default AchievementCard