import React from 'react'
import Heading from '../Heading'
import '../../styles/Experience.scss'
import ExperienceCards from './ExperienceCards'
import { useState } from 'react'
import Expdata from '../../data/ExperienceList'
// import educationImg from '../../img/static/education.png'
// import volunteeringImg from '../../img/static/volunteering.png'
// import workImg from '../../img/static/work.png'


const Experience = ({ theme }) => {
    const [selected, setSelected] = useState(1)
    return (
        <div className={'experience-main experience-main-' + theme} id="experience">
            <Heading title="Experience" theme={theme} />

            <div className='experience-main-container'>

                <div className={'experience-menu experience-menu-' + theme}>
                    <div className='row'>
                        <ul>
                            <li className={selected === 0 ? `one active active-${theme}` : "one"}>
                                <p className='col-4' onClick={() => setSelected(0)}>
                                    {/* <img className='experience-icon' alt="icon" src={educationImg} /> */}
                                    <i className="experience-icon fa fa-graduation-cap"></i>
                                    <span>Education</span>
                                </p>
                            </li>
                            <li className={selected === 1 ? `two  active active-${theme}` : "two"}>
                                <p className='col-4' onClick={() => setSelected(1)}>
                                    {/* <img className='experience-icon' alt="icon" src={workImg} /> */}
                                    <i className="experience-icon fa fa-briefcase"></i>
                                    <span>Work</span>
                                </p>
                            </li>
                            <li className={selected === 2 ? `three  active active-${theme}` : "three"}>
                                <p className='col-4' onClick={() => setSelected(2)}>
                                    {/* <img className='experience-icon' alt="icon" src={volunteeringImg} /> */}
                                    <i className="experience-icon fa fa-user-check"></i>
                                    <span>Volunteering</span>
                                </p>
                            </li>
                            <div className={"experience-underline experience-underline-" + theme}></div>
                        </ul>
                    </div>
                </div>
                <div className={'experience-display experience-display-' + theme}>
                    <ExperienceCards data={Expdata[selected]} theme={theme} />
                </div>
            </div>
        </div >
    )
}

export default Experience