import React from 'react'
import Icons from './Icons'
import '../../styles/Skills.scss'
import Heading from '../Heading'
// import { useState } from 'react'
// import gear from '../../img/static/gear.gif
import { Fade } from 'react-awesome-reveal'

const Skills = ({ theme }) => {
    return (
        <div id="skills" className={'skills-main skills-main-' + theme} >
            <Heading title="Skills" theme={theme} />
            <div className={'skills-container skills-container-' + theme}>
                <div>
                    <div>
                        <h4 className='skills-inner-heading'>Programming Languages</h4>
                        <div className='skills-container-section'>
                            <div className={'skills-icon-card skills-icon-card-' + theme}>
                                <Fade>
                                    <img className='skill-icon' alt="icon" src={Icons.js.source} />
                                    <p>JavaScript</p>
                                </Fade>
                            </div>
                            <div className={'skills-icon-card skills-icon-card-' + theme}>
                                <Fade>
                                    <img className='skill-icon' alt="icon" src={Icons.cpp.source} />
                                    <p>C++</p>
                                </Fade>
                            </div>
                            <div className={'skills-icon-card skills-icon-card-' + theme}>
                                <Fade>
                                    <img className='skill-icon' alt="icon" src={Icons.c.source} />
                                    <p>C</p>
                                </Fade>
                            </div>
                            <div className={'skills-icon-card skills-icon-card-' + theme}>
                                <Fade>
                                    <img className='skill-icon' alt="icon" src={Icons.python.source} />
                                    <p>Python</p>
                                </Fade>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h4 className='skills-inner-heading'>Technologies</h4>
                        <div className='skills-container-section'>
                            <div className={'skills-icon-card skills-icon-card-' + theme}>
                                <Fade>
                                    <img className='skill-icon' alt="icon" src={Icons.react.source} />
                                    <p>React</p>
                                </Fade>
                            </div>
                            <div className={'skills-icon-card skills-icon-card-' + theme}>
                                <Fade>
                                    <img className='skill-icon' alt="icon" src={Icons.node} />
                                    <p>NodeJS</p>
                                </Fade>
                            </div>
                            <div className={'skills-icon-card skills-icon-card-' + theme}>
                                <Fade>
                                    <img className='skill-icon' alt="icon" src={Icons.express} />
                                    <p>Express</p>
                                </Fade>
                            </div>
                            <div className={'skills-icon-card skills-icon-card-' + theme}>
                                <Fade>
                                    <img className='skill-icon' alt="icon" src={Icons.html.source} />
                                    <p>HTML</p>
                                </Fade>
                            </div>
                            <div className={'skills-icon-card skills-icon-card-' + theme}>
                                <Fade>
                                    <img className='skill-icon' alt="icon" src={Icons.css} />
                                    <p>CSS</p>
                                </Fade>
                            </div>
                            <div className={'skills-icon-card skills-icon-card-' + theme}>
                                <Fade>
                                    <img className='skill-icon' alt="icon" src={Icons.sass} />
                                    <p>Sass</p>
                                </Fade>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h4 className='skills-inner-heading'>Databases</h4>
                        <div className='skills-container-section'>
                            <div className={'skills-icon-card skills-icon-card-' + theme}>
                                <Fade>
                                    <img className='skill-icon' alt="icon" src={Icons.firebase} />
                                    <p>Firebase</p>
                                </Fade>
                            </div>
                            <div className={'skills-icon-card skills-icon-card-' + theme}>
                                <Fade>
                                    <img className='skill-icon' alt="icon" src={Icons.sql} />
                                    <p>SQL</p>
                                </Fade>
                            </div>
                            <div className={'skills-icon-card skills-icon-card-' + theme}>
                                <Fade>
                                    <img className='skill-icon' alt="icon" src={Icons.mongo} />
                                    <p>MongoDB</p>
                                </Fade>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h4 className='skills-inner-heading'>Tools</h4>
                        <div className='skills-container-section'>
                            <div className={'skills-icon-card skills-icon-card-' + theme}>
                                <Fade>
                                    <img className='skill-icon' alt="icon" src={Icons.git} />
                                    <p>Git</p>
                                </Fade>
                            </div>
                            <div className={'skills-icon-card skills-icon-card-' + theme}>
                                <Fade>
                                    <img className='skill-icon' alt="icon" src={Icons.github} />
                                    <p>Github</p>
                                </Fade>
                            </div>
                            <div className={'skills-icon-card skills-icon-card-' + theme}>
                                <Fade>
                                    <img className='skill-icon' alt="icon" src={Icons.linux} />
                                    <p>Linux</p>
                                </Fade>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h4 className='skills-inner-heading'>Design</h4>
                        <div className='skills-container-section'>
                            <div className={'skills-icon-card skills-icon-card-' + theme}>
                                <Fade>
                                    <img className='skill-icon' alt="icon" src={Icons.canva} />
                                    <p>Canva</p>
                                </Fade>
                            </div>
                            <div className={'skills-icon-card skills-icon-card-' + theme}>
                                <Fade>
                                    <img className='skill-icon' alt="icon" src={Icons.figma} />
                                    <p>Figma</p>
                                </Fade>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Skills