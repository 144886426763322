import React, { useState } from 'react'
import ProjectList from '../../data/ProjectList'
import Card from './Card'
import '../../styles/Projects.scss'
import Heading from '../Heading'
import { Zoom } from 'react-awesome-reveal'
import useWindowDimensions from '../../utils/WindowDimensions'


const Projects = ({ theme }) => {
    const { width } = useWindowDimensions();
    const [allProjects, setAllProjects] = useState(ProjectList)
    const [projectDisplay, setProjectDisplay] = useState(width < 768 ? ProjectList.slice(0, 3) : ProjectList.slice(0, 6))
    const [showMore, setShowMore] = useState(false)
    const [filters] = useState([{
        id: 1,
        fn: "all",
        title: "Show All"
    }, {
        id: 2,
        fn: "front-end",
        title: "Front End"
    }, {
        id: 3,
        fn: "full-stack",
        title: "Full Stack"
    }, {
        id: 4,
        fn: "react",
        title: "React",
    }, {
        id: 5,
        fn: "databases",
        title: "Databases"
    }])
    const [selectedFilter, setSelectedFilter] = useState(1)

    const filterProjects = (tag, id) => {
        let filtered = ProjectList
        if (tag !== "all")
            filtered = ProjectList.filter(proj => (proj.techs.includes(tag) || proj.tags.includes(tag)))
        setAllProjects(filtered)
        setProjectDisplay(width < 768 ? filtered.slice(0, 3) : filtered.slice(0, 6))
        setShowMore(filtered.length < 6)
        setSelectedFilter(id)
    }

    const displayMoreProjects = () => {
        setProjectDisplay(allProjects)
        setShowMore(true)
    }

    return (
        <div className={"projects-main projects-main-" + theme} id="projects">
            <Heading title="Projects" theme={theme} />
            <div className='mb-3'>
                {filters.map(el => (
                    <button key={el.id} className={el.id === selectedFilter ?
                        `projects-filter-btn projects-filter-btn-${theme} filter-selected-${theme}` :
                        `projects-filter-btn projects-filter-btn-${theme}`} onClick={() => filterProjects(el.fn, el.id)}>{el.title}</button>
                ))}

            </div>
            <div className="container">
                <div className="row row-cols-1 row-cols-lg-3 g-4">
                    {projectDisplay.map((project, index) => (
                        <div className="col-md-4 col-12" key={Math.random() + project._id}>
                            <Zoom>
                                <Card key={index} project={project} theme={theme} />
                            </Zoom>
                        </div>
                    ))}
                </div>
                <div>
                    <button className={showMore ? "d-none projects-show-more-btn projects-show-more-btn-" : "d-block projects-show-more-btn projects-show-more-btn-" + theme} onClick={displayMoreProjects}>Show More</button>
                </div>
            </div>
        </div >
    )
}

export default Projects