import React from 'react'
import '../styles/Navbar.scss'
import logo from '../img/logo_transparent.png'
import { Link } from "react-scroll"
import useWindowDimensions from '../utils/WindowDimensions'

const Navbar = ({ theme }) => {
    // console.log(theme)
    const { width } = useWindowDimensions();
    return (
        <nav className={"navbar navbar-expand-lg fixed-top navbar-custom-" + theme + " navbar-" + (theme === "dark" ? "light" : "dark")}>
            <div className="container-fluid">
                <a className="navbar-brand" href="/">
                    <img src={logo} alt="logo" />
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            {/* <a className="nav-link active" aria-current="page" href="/">Home</a> */}
                            {/* <a className="nav-link active"> </a> */}
                        </li>
                    </ul>
                    <ul className="navbar-nav mb-2 mb-lg-0 navbar-right-links">
                        <li className="nav-item">
                            <Link className="nav-link" to="experience" spy={true} smooth={true} data-bs-toggle={width < 768 ? "collapse" : null} data-bs-target={width < 768 ? "#navbarSupportedContent" : null}>Experience</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="skills" spy={true} smooth={true} data-bs-toggle={width < 768 ? "collapse" : null} data-bs-target={width < 768 ? "#navbarSupportedContent" : null}>Skills</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="projects" spy={true} smooth={true} data-bs-toggle={width < 768 ? "collapse" : null} data-bs-target={width < 768 ? "#navbarSupportedContent" : null}>Projects</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="achievements" spy={true} smooth={true} data-bs-toggle={width < 768 ? "collapse" : null} data-bs-target={width < 768 ? "#navbarSupportedContent" : null}>Achievements</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="github" spy={true} smooth={true} data-bs-toggle={width < 768 ? "collapse" : null} data-bs-target={width < 768 ? "#navbarSupportedContent" : null}>Github</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="cp" spy={true} smooth={true} data-bs-toggle={width < 768 ? "collapse" : null} data-bs-target={width < 768 ? "#navbarSupportedContent" : null}>CP</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="talks" spy={true} smooth={true} data-bs-toggle={width < 768 ? "collapse" : null} data-bs-target={width < 768 ? "#navbarSupportedContent" : null}>Talks</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="contact" spy={true} smooth={true} data-bs-toggle={width < 768 ? "collapse" : null} data-bs-target={width < 768 ? "#navbarSupportedContent" : null}>Contact</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav >
    )
}

export default Navbar