import React from 'react'
import '../styles/Landing.scss'
import Link from 'react-scroll/modules/components/Link'
import { Fade, Slide, Zoom } from "react-awesome-reveal";
import Typed from "typed.js";
import { useEffect, useRef } from "react";
import useWindowDimensions from '../utils/WindowDimensions'
import landingImg from '../img/landing_img.png'
import landingImgDark from '../img/landing_img_dark.png'

const Landing = ({ theme }) => {
    const { width } = useWindowDimensions();
    const el = useRef(null);
    useEffect(() => {
        const typed = new Typed(el.current, {
            strings: ["Developer", "Designer", "Creator", "Speaker"], // Strings to display
            // Speed settings, try diffrent values untill you get good results
            startDelay: 300,
            typeSpeed: 100,
            backSpeed: 100,
            backDelay: 100,
            loop: true,
        });

        // Destropying
        return () => {
            typed.destroy();
        };
    }, []);

    return (
        <div className={'landing-main landing-main-' + theme}>
            <Slide direction='left' delay={800} triggerOnce>
                <div className='landing-sidebar'>
                    <div>
                        <a href="mailto:vishruth.mec@gmail.com" target="_blank" rel="noreferrer" title="Email">
                            <div className={'landing-sidebar-icon-box landing-sidebar-icon-box-' + theme}>
                                <i className="far fa-envelope"></i>
                            </div>
                        </a>
                    </div>
                    <div>
                        <a href="https://www.linkedin.com/in/vishruth-subramanian/" target="_blank" rel="noreferrer" title="LinkedIn">
                            <div className={'landing-sidebar-icon-box landing-sidebar-icon-box-' + theme}>
                                <i className="fab fa-linkedin-in"></i>
                            </div>
                        </a>
                    </div>
                    <div>
                        <a href="https://github.com/Vishruth-S" target="_blank" rel="noreferrer" title="Github">
                            <div className={'landing-sidebar-icon-box landing-sidebar-icon-box-' + theme}>
                                <i className="fab fa-github"></i>
                            </div>
                        </a>
                    </div>
                    <div>
                        <a href="http://instagram.com/vishruth_s_" target="_blank" rel="noreferrer" title="Instagram">
                            <div className={'landing-sidebar-icon-box landing-sidebar-icon-box-' + theme}>
                                <i className="fab fa-instagram"></i>
                            </div>
                        </a>
                    </div>
                </div>
            </Slide>
            <div className='row landing-main-row'>
                <div className='col-2'>
                    {/* <div>
                        <ul>
                            <li>1</li>
                            <li>1</li>
                            <li>1</li>
                            <li>1</li>
                        </ul>
                    </div> */}
                </div>
                <div className='col-md-5 col-12 '>
                    <Fade direction="left">
                        <h1 className='landing-heading'>
                            Hi, I'm <span className={'landing-heading-color-' + theme}>Vishruth</span>
                        </h1>
                    </Fade>
                    <Fade direction="down">
                        <h2 className='landing-subheading'> <span ref={el} className={'landing-heading-color-' + theme}></span></h2>
                    </Fade>
                    <Fade direction="right">
                        <p className='landing-body'>
                            I'm an Engineer who graudated from Govt. Model Engineering College doing major in Computer Science.
                            I am passionate about Technology and the world of Software. I do projects when I get time and also enjoy doing Competitive coding :)
                        </p>
                    </Fade>
                    <Slide direction="up">
                        <div className='landing-btns'>
                            <a href="https://drive.google.com/file/d/1cVK_wGeZ9uJmVj79SuCoO3Mn5EFedc5l/view?usp=sharing" target="_blank" rel="noreferrer">
                                <span className='landing-btn-1'>
                                    <button className={'landing-resume-btn landing-resume-btn-' + theme}>Resume</button>
                                </span>
                            </a>
                            <Link to="contact" spy={true} smooth={true}>
                                <span className='landing-btn-2'>
                                    <span className={'hover-underline-animation hover-underline-animation-' + theme}>Contact</span>
                                </span>
                            </Link>
                        </div>
                    </Slide>
                </div>
                <div className='col-md-5 col-12'>
                    <Zoom>
                        {width > 768 ?
                            <div className={'landing-img-' + theme}>
                            </div>
                            :
                            theme === "light" ?
                                <img src={landingImg} className='landing-img-mobile' alt='vishruth profile' />
                                :
                                <img src={landingImgDark} className='landing-img-mobile' alt='vishruth profile' />
                        }

                    </Zoom>
                </div>
            </div>
        </div>
    )
}

export default Landing