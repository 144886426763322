import React from 'react'
import Heading from './Heading'
import GitHubCalendar from 'react-github-calendar';
import '../styles/Github.scss'
import octocat1 from '../img/static/octocat1.png'
import octocat2 from '../img/static/octocat2.png'
import octocat3 from '../img/static/octocat3.png'
import { Fade, Rotate } from 'react-awesome-reveal';
import useWindowDimensions from '../utils/WindowDimensions'

const Github = ({ theme }) => {
    const { width } = useWindowDimensions();
    return (
        <div id="github" className={'github-main github-main-' + theme}>
            <Heading title="Github Overview" theme={theme} />
            <div className='github-main-container'>
                <Rotate triggerOnce>
                    <img src={octocat3} alt="octocat" className='github-octocat octocat3' />
                </Rotate>
                <Fade triggerOnce={true}>
                    <img src={octocat1} alt="octocat" className='github-octocat octocat1' />
                </Fade>
                <Rotate triggerOnce>
                    <img src={octocat2} alt="octocat" className='github-octocat octocat2' />
                </Rotate>
                <div className="github-container">
                    <Fade >
                        <div className='github-stats'>
                            <a href="https://github.com/Vishruth-S" target="__blank">
                                {theme === 'light' ?
                                    <img alt="github stats" src="https://github-readme-stats.vercel.app/api?username=vishruth-s&show_icons=true&count_private=true&include_all_commits=true&hide_rank=true&title_color=000&text_color=000&icon_color=6736DD" />
                                    :
                                    <img alt="github stats" src="https://github-readme-stats.vercel.app/api?username=vishruth-s&show_icons=true&count_private=true&include_all_commits=true&hide_rank=true&bg_color=182538f2&title_color=f6f3fd&text_color=f6f3fd&icon_color=10C4F8&border_color=3b4052" />
                                }

                            </a>
                        </div>
                        <div className={'github-calendar-box github-calendar-box-' + theme}>
                            {theme === 'light' ?
                                <GitHubCalendar username="vishruth-s" color="6736DD" hideColorLegend style={{ margin: 'auto' }} fontSize={16} hideMonthLabels />
                                :
                                width < 768 ?
                                    <GitHubCalendar username="vishruth-s" style={{ margin: 'auto' }} fontSize={16} hideMonthLabels
                                        theme={{
                                            level0: "#41536e",
                                            level1: "#63c1dc",
                                            level2: "#2babce",
                                            level3: "#0eb4e2",
                                            level4: "#10C4F8",
                                        }}
                                        hideColorLegend
                                    />
                                    :
                                    <GitHubCalendar username="vishruth-s" style={{ margin: 'auto' }} fontSize={16} hideMonthLabels
                                        theme={{
                                            level0: "#41536e",
                                            level1: "#63c1dc",
                                            level2: "#2babce",
                                            level3: "#0eb4e2",
                                            level4: "#10C4F8",
                                        }} />
                            }

                        </div>
                    </Fade>
                </div>
            </div>
        </div>
    )
}

export default Github