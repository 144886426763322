import React from 'react'
import { Zoom } from 'react-awesome-reveal'
import useWindowDimensions from '../../utils/WindowDimensions'

const ExperienceCards = ({ data, theme }) => {
    const { width } = useWindowDimensions();
    return (
        <div>
            <section className="timeline">
                <div className="container">
                    {data.map(t => (
                        <div key={t.id + Math.random()} className="timeline-item">
                            <Zoom>
                                <div className={"timeline-img timeline-img-" + theme}></div>
                                <div className={"timeline-content js--ZoomInLeft timeline-content-" + theme}>
                                    {width < 600 || t.id % 2 !== 0 ?
                                        <div className='timeline-top'>
                                            <img className='timeline-top-img' src={t.img_link} alt="logo" />
                                            <div className='timeline-top-headers'>
                                                <h2>{t.company}</h2>
                                                <h5>{t.title}</h5>
                                            </div>
                                            <div className={"date odd-date theme-bg-" + theme}>{t.date}</div>
                                        </div>
                                        :
                                        <div className='timeline-top timeline-top-even'>
                                            <div className={"date even-date theme-bg-" + theme}>{t.date}</div>
                                            <div className='timeline-top-headers'>
                                                <h2>{t.company}</h2>
                                                <h5>{t.title}</h5>
                                            </div>
                                            <img className='timeline-top-img' src={t.img_link} alt="logo" />
                                        </div>
                                    }
                                    <div className='timeline-body'>
                                        <div>
                                            <ul>
                                                {t.content.map((c, idx) => (
                                                    <li key={idx} dangerouslySetInnerHTML={{ __html: c }}></li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                    {/* <a className="bnt-more" href="/">More</a> */}
                                </div>
                            </Zoom>
                        </div>
                    ))}
                </div>
            </section>
        </div>
    )
}

export default ExperienceCards