const achievementsList = [
    {
        id: 5,
        img_link: require("../img/static/firstPrize.png"),
        prize: "Winner",
        date: "Apr 2023",
        title: "Stranger Hacks: GPT Era",
        org: "Devfolio",
        desc: "National level hackathon organized by Devfolio, with a participation of 120 teams. Project Codie won the prize in this hackathon.",
        certificate_link: ""
    },
    {
        id: 10,
        img_link: require("../img/static/firstPrize.png"),
        prize: "Winner",
        date: "Dec 2020",
        title: "Code Innovation Series Hackathon",
        org: "IncubateIND and Github",
        desc: "National level hackathon organized by IncubateIND in association with Github, with a participation of over 100 teams",
        certificate_link: "https://drive.google.com/file/d/1iZuTM5xf79qW6ZDfbX-o_9L_eup9RHr-/view?usp=sharing"
    },
    {
        id: 20,
        img_link: require("../img/static/winner.png"),
        prize: "Award",
        date: "Dec 2020",
        title: "Millennium Fellowship",
        org: "United Nations Academic Impact",
        desc: "Selected as a Class of 2021 Millennium Fellow, joining over 2,000 leading peers from 120+ campuses from over 29 nations, all committed to having bold social impact",
        certificate_link: "https://drive.google.com/file/d/1rA-LbobPr3X4Xvfm2Ln9ZCo8AtNziAbv/view?usp=sharing",
    },
    {
        id: 30,
        img_link: require("../img/static/firstPrize.png"),
        prize: "First prize",
        date: "Jan 2021",
        title: "Lord of Code",
        org: "Excel MEC",
        desc: "Lord of Code is a national level coding contest held as a part of Excel 2020, the annual techno-manegerial fest of MEC. The event had over 200 participants",
        certificate_link: "https://drive.google.com/file/d/1mVlasTRj3on5cq3Ke3C_0nMNXMT9-GRn/view?usp=sharing",
    },
    {
        id: 40,
        img_link: require("../img/static/secondPrize.png"),
        prize: "Second prize",
        date: "Jan 2021",
        title: "Codemenders",
        org: "Excel MEC",
        desc: "Codemenders is a debugging contest  held as a part of Excel 2020, the annual techno-manegerial fest of MEC. The event had over 150 participants",
        certificate_link: "https://drive.google.com/file/d/1I3CMajrPOq2CMFz6dAEKlgE7ajVqKoQM/view?usp=sharing",
    },
    {
        id: 50,
        img_link: require("../img/static/thirdPrize.png"),
        prize: "Third prize",
        date: "Sep 2019",
        title: "Bitwise",
        org: "MACS",
        desc: "Bitwise is an intra-college coding contest conducted by MEC Association of Computer Students",
        certificate_link: "https://drive.google.com/file/d/1SWJAHgnwNEioGnq8X8Y3BgwlxQzOvpob/view?usp=sharing",
    },
    {
        id: 60,
        img_link: require("../img/static/firstPrize.png"),
        prize: "First prize",
        date: "Feb 2022",
        title: "Algorithms",
        org: "Excel MEC",
        desc: "Algorithms is a national level coding contest held as a part of Excel 2021, the annual techno-manegerial fest of MEC. The event had over 200 participants",
        certificate_link: "/",
    },
    {
        id: 70,
        img_link: require("../img/static/winner.png"),
        prize: "Winner",
        date: "Feb 2022",
        title: "CodeX 2.0",
        org: "Scaler",
        desc: "Ranked 679 from over 1 lakh participants for CodeX 2.0, a competitive programming contest conducted by Scaler",
        certificate_link: "/",
    },
    {
        id: 80,
        img_link: require("../img/static/thirdPrize.png"),
        prize: "Third Prize",
        date: "Mar 2022",
        title: "DevCraft",
        org: "Excel MEC",
        desc: "DevCraft is a design and development challenge where participants have to design and develop a working application in 48 hours",
        certificate_link: "/",
    },
    {
        id: 90,
        img_link: require("../img/static/firstPrize.png"),
        prize: "First Prize",
        date: "Oct 2021",
        title: "Cicada",
        org: "IEDC JEC",
        desc: "Cicada is a inter-college competitive coding contest organized by IEDC JEC. The contest had a participation of 50+",
        certificate_link: "https://assessment.hackerearth.com/challenges/college/CICADA/leaderboard/",
    },
    {
        id: 100,
        img_link: require("../img/static/secondPrize.png"),
        prize: "Second Prize",
        date: "Mar 2022",
        title: "Debugger",
        org: "Excel MEC",
        desc: "Second Prize in a debugging contest  held as a part of Excel 2021, the annual techno-manegerial fest of MEC.",
        certificate_link: "/",
    },
    {
        id: 110,
        img_link: require("../img/static/winner.png"),
        prize: "Winner",
        date: "Apr 2022",
        title: "Job-a-thon 8.0",
        org: "GeeksforGeeks",
        desc: "Job-a-thon is a hiring/competitive programming contest conducted by GeeksforGeeks.",
        certificate_link: "https://drive.google.com/file/d/1uCq7oNVQzKk9UNdM5zCiquIGDt9OjNtY/view?usp=sharing",
    },
]

export default achievementsList