import React from 'react'
import Heading from '../Heading';
import '../../styles/CP.scss'
import hackerrank from '../../img/static/hackerrank_logo.png'
import codechef from '../../img/static/codechef_logo.jpg'
import leetcode from '../../img/static/leetcode_logo.png'

const CP = ({ theme }) => {
    return (
        <div id="cp" className={'cp-main cp-main-' + theme}>
            <Heading title="Competitive Coding" theme={theme} />
            <div className=' cp-card-continer'>
                <figure className="icon-cards">
                    <div className="icon-cards__content">
                        <div className={"d-flex align-items-center justify-content-center icon-cards__item icon-cards__item-" + theme}>
                            <div className={'cp-card cp-card-' + theme}>
                                <img className="cp-card-img" src={hackerrank} alt="hackerrank" />
                                <h3>HackerRank</h3>
                                <div className='cp-card-content'>
                                    <ul>
                                        <li>6 stars (top 0.01%) in Problem solving</li>
                                        <li>Certified in Problem solving</li>
                                    </ul>
                                </div>
                                <div>
                                    <a href='https://www.hackerrank.com/Vishruth_S' target="_blank" rel="noreferrer"><button>View profile</button></a>
                                </div>
                            </div>
                        </div>
                        <div className={"d-flex align-items-center justify-content-center icon-cards__item icon-cards__item-" + theme}>
                            <div className={'cp-card cp-card-' + theme}>
                                <img className="cp-card-img" src={leetcode} alt="hackerrank" />
                                <h3>Leetcode</h3>
                                <div className='cp-card-content'>
                                    <ul>
                                        <li>350+ problems solved</li>
                                        <li>Longest streak 31 days</li>
                                    </ul>
                                </div>
                                <div>
                                    <a href='https://leetcode.com/Vishruth_S/' target="_blank" rel="noreferrer"><button>View profile</button></a>
                                </div>
                            </div>
                        </div>
                        <div className={"d-flex align-items-center justify-content-center icon-cards__item icon-cards__item-" + theme}>
                            <div className={'cp-card cp-card-' + theme}>
                                <img className="cp-card-img" src={codechef} alt="hackerrank" />
                                <h3>Codechef</h3>
                                <div className='cp-card-content'>
                                    <ul>
                                        <li>Highest rating 1633, 3 stars</li>
                                        <li>Ranked 761 globally in May starters contest</li>
                                    </ul>
                                </div>
                                <div>
                                    <a href='https://www.codechef.com/users/vishruth_s' target="_blank" rel="noreferrer"><button>View profile</button></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </figure>
            </div>

        </div>
    )
}

export default CP