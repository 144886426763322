const Work = [
    {
        id: 8,
        company: "Ansys",
        title: "Research & Development Engineer",
        date: "Jul 2023 - Present",
        content: ["Working on the flagship Redhawk SC Product", "Responsible for the entire development cycle of multiple features, from design to implementation to testing", "Technologies - C++, Python, Git, Jenkins, Linux"],
        img_link: require('../img/companyLogos/ansys.png'),
        link: '/'
    },
    {
        id: 20,
        company: "Ansys",
        title: "R&D Engineer Intern",
        date: "Aug 2022 - Jun 2023",
        content: ["Researched in optimizing compilation times for the flagship Redhawk SC Product", "<b>Reduced compilation times by 10%</b>, helping developers save time", "<b>Shipped a new feature</b> that <b>reduces the size of log files by 80%</b>, saving over <b>100GB</b> of disk space with each run"],
        img_link: require('../img/companyLogos/ansys.png'),
        link: '/'
    },
    {
        id: 22,
        company: "EDC Creative Technologies",
        title: "Software Developer Intern",
        date: "Aug 2021 - Oct 2021",
        content: ["Collaborated with cross-functional teams to <b>develop a web application</b> for an Electric Vehicle Company, and helped increase their online presence and outreach to over <b>10000 users</b> over India",
            "<b>Kickstarted the process</b> of upgrading the entire codebase for a project from <b>ReactJS to NextJS</b>",
            "<b>Links - </b><a href='https://drive.google.com/file/d/18JtG9WL8qI3luQ9c5U-J2b0Ja5dGh-CT/view?usp=sharing' target='_blank'>Certificate of internship</a>"
        ],
        img_link: require('../img/companyLogos/edc.jpg'),
        link: '/'
    },
    {
        id: 30,
        company: "IEDC MEC",
        title: "Front-end Developer",
        date: "Jan 2021 - Jul 2021",
        content: ["Collaborated with teams to build modern and responsive websites for IEDC MEC, the Innovation and Entrepreneurship Cell of Govt. Model Engineering College",
            "<b>Technologies</b> - ReactJS, Firebase, Javascript, HTML, CSS, MaterialUI",
            "<b>Link to sample work - </b><a href='https://iedcmec.in' target='_blank'>IEDC MEC Website</a>"],
        img_link: require('../img/companyLogos/iedc.jpg'),
        link: '/'
    },
    {
        id: 44,
        company: "Excel MEC",
        title: "Web Developer",
        date: "Nov 2020 - Nov 2022",
        content: ["Lead the team in translating designs to efficient code for developing user interfaces for various websites of EXCEL, the national level Tech-fest of Govt. Model Engineering College",
            "<b>Technologies</b> - ReactJS, Javascript, HTML, CSS",
            "<b>Link to sample work - </b><a href='https://vishruth-s.github.io/excel2020marketing/' target='_blank'>Marketing Website</a>"],
        img_link: require('../img/companyLogos/excel.jpg'),
        link: '/'
    }
]

const Education = [
    {
        id: 1,
        company: "Govt. Model Engineering College",
        title: "B.Tech Computer Science",
        date: "Jul 2019 - Jul 2023",
        content: ["Major in Computer Science with basic software subjects like Data Structures, Operating Systems, DBMS, Computer Networks, Automata Theory",
            "Recipient of the prestigious <a href='https://drive.google.com/file/d/1feHVChA6_tDL6QdLhDprXfQ9JgyWxRX3/view?usp=sharing' target='_blank'>XSA Merit Scholarship</a>, which is given to top 5% of students in college",
            "Received SGPA 10 (Rank 1) in semesters 1, 2, 3 and 4",
            "<b>CGPA: 9.71</b>"],
        img_link: require('../img/companyLogos/mec.jpg'),
        link: '/'
    },
    {
        id: 2,
        company: "Bhavan's Vidya Mandir, Girinagar",
        title: "Higher Secondary",
        date: "Jun 2007 - May 2019",
        content: ["Majored in Physics, Chemistry, Mathematics and Computer Science",
            "Ranked among top 3 in school for AISSCE Board Exam 2019",
            "Extracurricular activities: Science Exhibitions, Quizzes and Presentations",
            "<b>Score: 96.5%</b>"],
        img_link: require('../img/companyLogos/bvmg.png'),
        link: '/'
    },
]

const Volunteering = [
    {
        id: 1,
        company: "TLE MEC",
        title: "Co-founder & Chairperson",
        date: "Sep 2021 - Sep 2022",
        content: ["TLE MEC is a Technical Club of Govt. Model Engineering College with the vision of building a warm and welcoming Competitive Programming culture on our campus so as to help students become better problem solvers by being involved in an active community and discovering the fun in competition."],
        img_link: require('../img/companyLogos/tle.png'),
        link: '/'
    },
    {
        id: 2,
        company: "Codechef MEC Chapter",
        title: "Chapter Lead",
        date: "Aug 2020 - Aug 2021",
        content: ["CodeChef MEC is the Codechef Chapter of Govt. Model Engineering College. It is a community that focuses on building the culture of competitive programming in the campus."],
        img_link: require('../img/companyLogos/codechefmec.jpg'),
        link: '/'
    },
    {
        id: 3,
        company: "Google DSC MEC",
        title: "Core member, Web Team ",
        date: "Jul 2020 - Jul 2021",
        content: ["Google Developer Student Clubs is a community of college and university students interested in Google developer technologies."],
        img_link: require('../img/companyLogos/dscmec.jpg'),
        link: '/'
    },
]

const Expdata = [Education, Work, Volunteering]

export default Expdata