import React from 'react'
import Heading from '../Heading'
import AchievementCard from './AchievementCard'
import achievementsList from '../../data/AchievementsList'
// import dragicon from '../../img/static/drag_icon.svg'

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/scss";
import "swiper/scss/pagination";
import "swiper/scss/navigation";

import '../../styles/Swiper.scss'
// import required modules
import { Pagination, Navigation, Autoplay } from "swiper";
// import Particles from "react-tsparticles";
// import { Engine } from "tsparticles-engine";
// import { loadConfettiPreset } from "tsparticles-preset-confetti";

import useWindowDimensions from '../../utils/WindowDimensions'
import dragicon from '../../img/static/drag_icon.png'
import dragiconDark from '../../img/static/drag_icon_dark.png'

export const Achievements = ({ theme }) => {
    // const customInit = async (engine) => {
    //     // this adds the preset to tsParticles, you can safely use the
    //     await loadConfettiPreset(engine);
    // }
    // const options = {
    //     preset: "confetti",
    // };
    const { width } = useWindowDimensions();

    return (
        <div className={'achievements-container achievements-container-' + theme} id="achievements">
            {/* <Particles options={options} init={customInit} />; */}
            <Heading title="Achievements" theme={theme} />
            <Swiper
                slidesPerView={width < 600 ? 1 : 2}
                spaceBetween={0}
                slidesPerGroup={width < 600 ? 1 : 2}
                loop={true}
                loopFillGroupWithBlank={true}
                pagination={{
                    clickable: true,
                    dynamicBullets: true,
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                autoplay={false}
                className="mySwiper"
            >
                {achievementsList.map(ac => (
                    <SwiperSlide key={ac.id}><AchievementCard key={ac.id} achievement={ac} theme={theme} /></SwiperSlide>
                ))}
            </Swiper>
            <div className={'achievements-drag-icon achievements-drag-icon-' + theme} >
                {theme === 'light' ?
                    <img src={dragicon} alt="icon" />
                    :
                    <img src={dragiconDark} alt="icon" />
                }

                <p>Drag to move</p>
            </div>
        </div >
    )
}
